<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}${title}`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="供应商" prop="supplier.id" v-if="code === 'PUC'">
        <select-maintain
          v-model="form.data.supplier"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, type: 'B', status: 'A', rsexp: 'id,name' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          @change="form.supplier.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="入货仓库" prop="warehouse.id" v-if="code === 'PUC'">
        <select-maintain
          v-model="form.data.warehouse"
          :ajax="{
            action: 'GET /enocloud/common/warehouse',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          @change="form.warehouse.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="订单类型"  v-if="code === 'PUC'">
        <select-maintain
          v-model="form.data.style"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['PCSTYE'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="运费支付" prop="freightPaymentMethod" v-if="code === 'PUC'">
        <select-maintain
          v-model="form.data.freightPaymentMethod"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['FRPMMTD'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.disabled"
          :clearable="false"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="物流费用" prop="freight" v-if="code === 'PUC'&&form.data.freightPaymentMethod?.code!=='N'">
        <en-input-number v-model="form.data.freight" :disabled="form.disabled" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="税率(%)" v-if="code === 'PUC'">
        <select-maintain
          v-model="form.data.taxRate"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['TAXRT'])
          }"
          :props="{ label: (option) => parseFloat(option.name) * 100, value: 'name' }"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="物流公司" v-if="code === 'PUC'&&form.data.freightPaymentMethod?.code==='N'">
        <select-maintain
          v-model="form.data.logisticsCompany"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, type: 'L', rsexp: 'id,name', pagingEnabled: false })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
        
      </en-form-item>
      <en-form-item label="物流公司" prop="logisticsCompany" v-if="code === 'PUC'&&form.data.freightPaymentMethod?.code!=='N'">
        <select-maintain
          v-model="form.data.logisticsCompany"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, type: 'L', rsexp: 'id,name', pagingEnabled: false })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>

      <en-form-item label="发票类型" v-if="code === 'PUC'">
        <select-maintain
          v-model="form.data.invoiceTypeName"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['INVCTP'])
          }"
          :props="{ label: 'name', value: 'name' }"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
     
      <en-form-item label="计划到货" v-if="code === 'PUC'">
        <en-date-picker v-model="form.data.plannedArrivalDate" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="采购日期" v-if="code === 'PUC'">
        <en-date-picker v-model="form.data.businessDate" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="备注" v-if="code === 'PUC'">
        <en-input v-model="form.data.comment" type="textarea" :disabled="form.data.settlementProgress?.code === 'S'"></en-input>
      </en-form-item>
      <en-form-item label="物流单" v-if="code === 'PUC'">
        <upload-maintain v-model="form.data.imgUrls" multiple dir="purchase"></upload-maintain>
      </en-form-item>

      <en-form-item label="采退日期" v-if="code === 'PUCR'">
        <en-date-picker v-model="form.data.businessDate" class="w-full" disabled></en-date-picker>
      </en-form-item>
      <en-form-item label="单据单号" v-if="code === 'PUCR'">
        <en-input v-model="form.data.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="制单人" v-if="code === 'PUCR'">
        <en-input v-model="form.data.preparedBy.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="部门" v-if="code === 'PUCR'">
        <en-input :model="form.data.preparedBy.department && form.data.preparedBy.department.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="经手人" v-if="code === 'PUCR'">
        <en-input :model="form.data.auditBy && form.data.auditBy.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="审核日期" v-if="code === 'PUCR'">
        <en-date-picker v-model="form.data.auditDatetime" disabled class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="供应商" prop="supplier.id" v-if="code === 'PUCR'">
        <select-maintain
          v-model="form.data.supplier"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, type: 'B', status: 'A', rsexp: 'id,name' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          @change="form.supplier.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="门店" v-if="code === 'PUCR'" >
        <en-input v-model="form.data.branch.shortName" disabled ></en-input>
      </en-form-item>


      <en-form-item label="物流公司" prop="logisticsCompany.id" v-if="code === 'PUCR'">
        <select-maintain
          v-model="form.data.logisticsCompany"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, type: 'L', rsexp: 'id,name', pagingEnabled: false })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="运费支付" prop="freightPaymentMethod" v-if="code === 'PUCR'">
        <select-maintain
          v-model="form.data.freightPaymentMethod"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['FRPMMTD'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="发票类型" v-if="code === 'PUCR'">
        <select-maintain
          v-model="form.data.invoiceTypeName"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['INVCTP'])
          }"
          :props="{ label: 'name', value: 'name' }"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="采退类型" v-if="code === 'PUCR'">
        <select-maintain
          v-model="form.data.otherType"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['RPCTYPE'])
          }"
          :props="{ label: 'name', value: 'name' }"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
    
   
      <en-form-item label="备注" v-if="code === 'PUCR'">
        <en-input v-model="form.data.comment" type="textarea" :disabled="form.data.settlementProgress?.code === 'S'"></en-input>
      </en-form-item>
      <en-form-item label="单据日期" v-if="code === 'OTH'">
        <en-date-picker v-model="form.data.preparedDatetime" disabled class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="单据单号" v-if="code === 'OTH'">
        <en-input v-model="form.data.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="制单人" v-if="code === 'OTH'">
        <en-input v-model="form.data.preparedBy.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="经手人" v-if="code === 'OTH'">
        <en-input v-model="form.data.auditBy.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="审核日期" v-if="code === 'OTH'">
        <en-date-picker v-model="form.data.auditDatetime" disabled class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="供应商" v-if="code === 'OTH'">
        <select-maintain
          v-model="form.data.supplier"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, type: 'B', status: 'A', rsexp: 'id,name' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          @change="form.supplier.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="入货仓库" prop="warehouse" v-if="code === 'OTH'">
        <select-maintain
          v-model="form.data.warehouse"
          :ajax="{
            action: 'GET /enocloud/common/warehouse',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          @change="form.warehouse.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="批次号" v-if="code === 'OTH'">
        <en-input v-model="form.data.batchNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="门店" v-if="code === 'OTH'">
        <en-input v-model="form.data.branch.shortName" disabled></en-input>
      </en-form-item>
      <en-form-item label="进货类型" prop="otherType" v-if="code === 'OTH'">
        <select-maintain
          v-model="form.data.otherType"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['OPCTYPE'])
          }"
          :props="{ label: 'name', value: 'name' }"
       
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="计划到货" v-if="code === 'OTH'">
        <en-date-picker v-model="form.data.plannedArrivalDate" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="备注" v-if="code === 'OTH'">
        <en-input v-model="form.data.branch.comment" type="textarea"></en-input>
      </en-form-item>
      <en-form-item label="税率" v-if="code === 'OTH'">
  <select-maintain
          v-model="form.data.taxRate"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['TAXRT'])
          }"
          :props="{ label: (option) => parseFloat(option.name) * 100, value: 'name' }"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
  
      <en-form-item label="单据日期" v-if="code === 'TRA'">
        <en-date-picker v-model="form.data.preparedDatetime" disabled class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="单据单号" v-if="code === 'TRA'">
        <en-input v-model="form.data.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="部门" v-if="code === 'TRA'">
        <en-input v-model="form.data.preparedBy.department.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="经手人" v-if="code === 'TRA'">
        <en-input v-model="form.data.auditBy.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="审核日期" v-if="code === 'TRA'">
        <en-date-picker v-model="form.data.auditDatetime" disabled class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="入库仓库" v-if="code === 'TRA'">
        <en-input v-model="form.data.stockTransferOut.toWarehouse.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="备注" v-if="code === 'TRA'">
        <en-input v-model="form.data.comment" type="textarea" :disabled="form.data.settlementProgress?.code === 'S'"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'

import dayjs from 'dayjs'
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudInventoryDefinitions['PurchaseDto'] | EnocloudInventoryDefinitions['StockTransferInGoodsDto']>,
    code: String as PropType<'PUC' | 'PUCR' | 'OTH' | 'TRA'>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          if (this.data?.id) {
            Object.assign(this.form.data, this.data)
            this.form.get()
          }
        } else {
          this.form.init()
        }
      }
    }
  },

  computed: {
    title() {
      switch (this.code) {
        case 'PUC':
          return '采购单'
        case 'PUCR':
          return '采退单'
        case 'OTH':
          return '其他进货单'
        case 'TRA':
          return '移库入库单'
      }
    }
  },

  config: {
    children: {
      form: {
        data: {
          type: { code: 'PUC' },
          purchaseGoods: [] as EnocloudInventoryDefinitions['PurchaseGoodsDto'][],
          preparedBy: {
            name: '',
            department: { name: '' }
          },
          auditBy: {
            name: ''
          },
          branch: {
            shortName: ''
          },
          businessDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
          stockTransferOut: { toWarehouse: 'name' },
          freightPaymentMethod:{code:'N'}
        },
        rules: {
          'supplier.id': [{ required: true, message: '请选择供应商' }],
          logisticsCompany: [{ required: true, message: '请填写物流费用' }],
          warehouse: [{ required: true, message: '请选择入货仓库' }],
          otherType: [{ required: true, message: '请选择进货类型' }],
          freightPaymentMethod: [{ required: true, message: '请选择运费支付方式' }],
          freight: [{ required: true, message: '请添加物流费用' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/purchase/:purchaseId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/purchase',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/purchase',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A' || this.form.data.status?.code === 'S'
          }
        },

        children: {
          supplier: {
            async change() {
              if (this.form.data.supplier?.id) {
                const res = await this.ajax('GET /enocloud/purchase/:supplierId/supplierrecent', { paths: [this.form.data.supplier.id] })
                this.form.data.warehouse = res.data[0]?.warehouse
                this.form.data.branch = res.data[0]?.warehouse?.branch
                this.form.data.invoiceTypeName = res.data[0]?.invoiceTypeName
                this.form.data.logisticsCompany = res.data[0]?.logisticsCompany
                this.form.data.freightPaymentMethod = res.data[0]?.freightPaymentMethod
              }
            }
          },
          warehouse: {
            change() {
              this.form.data.branch = this.form.data.warehouse?.branch
            }
          },
          taxRatePercent: {
            change() {
              for (const item of this.form.data.purchaseGoods ?? []) {
                item.preTaxPrice = calculator.div(item.price ?? 0, calculator.add(1, this.form.data.taxRate))
                item.preTaxAmount = calculator.mul(item.preTaxPrice, item.count)
              }
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.emit('update:model-value', false)
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
